import React, { useContext, useCallback, useState, useEffect, useMemo, createRef } from "react";
import { Box, CircularProgress, FormLabel, Grid, Switch } from "@mui/material";
import {
  AddButton,
  FilterButton,
  FilterButtonMultiple,
} from "../../reusable/Buttons";
import { SearchBox, DatePickerTextField } from "../../reusable/TextField";
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  CustomTab,
  CustomTabs,
  LabelDivider,
  SpacedRow,
  PaddingBoxSmall,
} from "../../reusable/Scaffolds";
import { ShiftItemList } from "../../reusable/List";
import { get, post, getLocalStorageShiftTab, setLocalStorageShiftTab, removeLocalStorageShiftTab } from "../../../utils/api";
import { Row } from "../../reusable/Scaffolds";
import { checkIfDateIn7Day, debounce } from "../../../utils/helper_functions";
import { useNavigate } from "react-router-dom";
import {
  SHIFT_TABS,
  unreadMessageFilter,
} from "../../../utils/constants";
import { CreateShiftSuccessModal } from "../../reusable/Modal";
import { OrgContext, UserContext } from "../../../utils/context";
import CustomBadge from "../../reusable/BadgeStyles";
import { M3TitleLarge, M3TitleSmall } from "../../reusable/TextStyles";
import { CreateShiftModal } from "./components/create_shift_modal";
import dayjs from "dayjs";
import { compareTwoDates, isValidDate } from "../../../utils/time";
import _  from "lodash";
import theme from "../../../utils/theme";
const filterUnreadConversation = (conversations, current_user_id) => {
  return conversations.filter((conversation) => conversation.conversation.unread_number_employer > 0 && conversation.last_message_user_id !== current_user_id);
}
const tagUnreadMessage = (shifts, current_user_id) => {
  return shifts.map((shift) => {
    const unreadConversation = shift.conversations === null ? [] : filterUnreadConversation(shift.conversations, current_user_id);

    const has_unread = shift.conversations && unreadConversation.length > 0;
    return {
      ...shift,
      has_unread,
    };
  });
}
const Shifts = ({ setAlert }) => {
  const navigate = useNavigate();
  const [shifts, setShifts] = React.useState([]);
  const [unreadShifts, setUnreadShifts] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState(SHIFT_TABS.OPEN);
  const [selectedCentreLocation, setSelectedCentreLocation] = React.useState(
    []
  );
  const [selectedAssignmentGroups, setSelectedAssignmentGroups] = React.useState([])
  const [centreLocations, setCentreLocations] = React.useState([]);
  const [organisationOptions, setOrganisationOptions] = React.useState([]);
  const [searchingQuery, setSearchingQuery] = React.useState("");
  const { startDate: startDateDefault, endDate: endDateDefault } = getCurrentWeekDateRange();
  const [startDate, setStartDate] = React.useState(startDateDefault);
  const [endDate, setEndDate] = React.useState(endDateDefault);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [qualifications, setQualifications] = React.useState([]);
  const [openModalCreateShiftSuccess, setOpenModalCreateShiftSuccess] =
    React.useState(false);
  const [createdShifts, setCreatedShifts] = React.useState(null);
  const [centreCreatedShift, setCentreCreatedShift] = React.useState(null);
  const [lastPage, setLastPage] = React.useState(1);

  const org = useContext(OrgContext)
  const org_id = org ? org.organisation_id : null
  const tabValues = useMemo(() => [
    { name: "Open", value: 0 },
    { name: "Filled", value: 1 },
    { name: "Finished", value: 2 },
    { name: "Completed", value: 3 },
    { name: "Cancelled", value: 4 },
    { name: "Expired", value: 5 }
  ], []);
  const ADMIN_HEIGHT = 300, USER_HEIGHT = 350
  const [filterUnreadMessageStatus, setFilterUnreadMessageStatus] = useState(1)
  const currentUser = useContext(UserContext)
  const [totalShifts, setTotalShifts] = useState({});
  const [disableCentre, setDisableCentre] = useState(currentUser?.is_quickcare_admin);
  const [currentPage, setCurrentPage] = useState(1)
  const [isDisabled, setIsDisabled] = useState(true)
  const [height, setHeight] = useState(currentUser.is_quickcare_admin?  window.innerHeight - ADMIN_HEIGHT: window.innerHeight - USER_HEIGHT);
  const [orgName, setOrgName] = useState(org ? org.organisation_name : null)
  const [selectedOrganisation, setSelectedOrganisation] = React.useState('0');
  const DEFAULT_CENTRE = '0'
  const DEFAULT_ORG = '0'
  const listRef = createRef();
  const handleSelectedOrganisationChange = (event) => {
    const selectedOrg = event.target.value;
    setSelectedOrganisation(selectedOrg);
    setSelectedCentreLocation([]);
    setDisableCentre(selectedOrg === DEFAULT_ORG)
    fetchCentres(selectedOrg !== DEFAULT_ORG ? selectedOrg : null);
    setIsDisabled(selectedOrg === DEFAULT_ORG)
    setOrgName(organisationOptions.find(item => item.id === selectedOrg)?.name)
  }

  const navigateToDetail = (shift_id) => {
    setLocalStorageShiftTab(currentTab);
    navigate(`/shifts/${shift_id}`);
  }

  const getCentreIdsFilter =() => {
    if(!currentUser.is_quickcare_admin) {
      return selectedCentreLocation
    }
    if (selectedOrganisation === DEFAULT_ORG) {
      return ''
    }
    if(selectedCentreLocation.length && selectedCentreLocation[0] !== DEFAULT_CENTRE) {
      return selectedCentreLocation
    }
    return centreLocations.filter(centre => centre.organisation_id === selectedOrganisation)
    .map(centre => centre.id)
  }

  const fetchShiftMessage = async (isRefresh, currentPage) => {
    setIsLoading(true)
    const storedTab = getLocalStorageShiftTab()
    let tabValue = currentTab
    if (storedTab) {
      tabValue = parseInt(storedTab)
      setCurrentTab(tabValue)
      removeLocalStorageShiftTab()
    }
    const { startDate: defaultStartDay, endDate: defaultEndDay } =
      getCurrentWeekDateRange()

    const params = {
      is_open: tabValue === SHIFT_TABS.OPEN,
      is_filled: tabValue === SHIFT_TABS.FILLED,
      is_recent: tabValue === SHIFT_TABS.FINISHED,
      is_completed: tabValue === SHIFT_TABS.COMPLETED,
      is_canceled: tabValue === SHIFT_TABS.CANCELLED,
      is_expired: tabValue === SHIFT_TABS.EXPIRED,
      text_query: searchingQuery,
      current_page: isRefresh? 1: currentPage,
      centre_ids: getCentreIdsFilter(),
      start_date: startDate || defaultStartDay,
      end_date: endDate || defaultEndDay,
      assignment_groups: selectedAssignmentGroups
    }
    try {
      const res = await get('/manage/shifts', null, params)
      const pagination = res.pagination
      if (pagination) {
        setLastPage(pagination.lastPage)
      }
      const taggedShifts = tagUnreadMessage(res.data, currentUser.id)
      const finalShiftList = (currentPage === 1 || isRefresh) ? taggedShifts : [...shifts, ...taggedShifts]
      setShifts(finalShiftList)
      setUnreadShifts(finalShiftList.filter((shift) => shift.has_unread))
      const totalShifts = await get(
        '/manage/shifts-count-statistic',
        null,
        params
      )
      setTotalShifts(totalShifts)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setAlert('Failed to fetch shifts')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const fetchCentres = useCallback(async (org_id_filter = org_id) => {
    if(org_id_filter) {
      const res = await get(`/manage/organisations/${org_id_filter}/centres`);
      setCentreLocations(res);
    }
  }, [org_id]);

  const fetchOrganisations = useCallback(async () => {
    const res = await get(`/admin/organisations`);
    const organisationList = res.map(org => ({ id: org.id, name: org.name }))
    setOrganisationOptions(organisationList)
  }, []);

  const fetchQualifications = async () => {
    const res = await get("/manage/qualifications");
    setQualifications(res.qualifications);
  };

  const handlePostShift = async (body) => {
    setCurrentPage(1);
    setLastPage(1);
    try {
      const res = await post("/manage/v1/shifts", body);
      setCreatedShifts(res.added_shifts);
      if(!centreLocations.find((element) => element.id.toString() === body.centre_id.toString()))
      {
        /// Sentry log
        console.error("Centre not found");
        console.error("Centre not found", body);
        console.error("Centre not found", centreLocations);
      }
      setCentreCreatedShift(
        centreLocations.find((element) => element.id.toString() === body.centre_id.toString())
      );
      setOpenModal(false);
      setOpenModalCreateShiftSuccess(true);
      fetchShiftMessage(true);
    } catch (error) {
      setAlert('Shift posted failed');
    }
  };
  const getMaxDate = () => {
    if (currentTab === SHIFT_TABS.FINISHED) {
      return dayjs()
    }

    return null
  }

  const getOrgId = useMemo(() => {
    if (currentUser.is_quickcare_admin) {
      return parseInt(selectedOrganisation)
    }

    return org_id;
  }, [currentUser.is_quickcare_admin, org_id, selectedOrganisation]);

  const getOrgName = useMemo(() => {
    if (currentUser.is_quickcare_admin) {
      if (selectedOrganisation !== DEFAULT_ORG) {
        const currentOrg = organisationOptions?.find(org => {
          return org.id === parseInt(selectedOrganisation)
        })

        return currentOrg?.name;
      }

      return 'Organisation'
    }

    return orgName;
  }, [currentUser.is_quickcare_admin, orgName, organisationOptions, selectedOrganisation])

  const handleTabChange = (event, newValue) => {
    if (newValue === SHIFT_TABS.FINISHED) {
      setEndDate(dayjs().format('YYYY-MM-DD'));
    } else {
      if (!startDate && !endDate) {
        const { startDate, endDate } = getCurrentWeekDateRange();
        setStartDate(startDate);
        setEndDate(endDate);
      }
    }
    debounce(setCurrentTab(newValue), 500);
  };
  const onChangeSearch = (searchString) => {
    debounce(setSearchingQuery(searchString), 500);
  };
  const updateDateFilter = (isStartDate, date) => {
    if (!isValidDate(date)) {
      return;
    }

    if (isStartDate) {
      setStartDate(date);
      if (!!endDate && compareTwoDates(date, endDate) === 1) {
        setEndDate(date);
      }
    } else {
      setEndDate(date);
      if (!!startDate && compareTwoDates(startDate, date) === 1) {
        setStartDate(date);
      }
    }

    _.debounce(() => {
      if (isValidDate(date) && isValidDate(isStartDate ? endDate : startDate)) {
      }
    }, 500)();
  };
  const handleSelectedCentresChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCentreLocation(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleAssignmentGroupChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAssignmentGroups(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const getShifts = () => {
    if (filterUnreadMessageStatus === 1) {
      return shifts
    } else {
      return unreadShifts
    }
  }

  function getCurrentWeekDateRange() {
    const currentDate = dayjs();
    const startOfWeek = currentDate.startOf('week').add(1, 'day');
    const endOfWeek = startOfWeek.add(4, 'day');
    let startDate =  startOfWeek.format('YYYY-MM-DD');
    const endDate = endOfWeek.format('YYYY-MM-DD');
    return { startDate, endDate };
  }

  useEffect(() => {
    fetchQualifications();
  }, []);

  useEffect(() => {
    if(currentUser.is_quickcare_admin) {
      fetchOrganisations();
    }
  }, [currentUser.is_quickcare_admin, fetchOrganisations]);

  useEffect(() => {
      fetchCentres();
  }, [fetchCentres]);

  useEffect(() => {
    setCurrentPage(1)
    fetchShiftMessage(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCentreLocation,
    searchingQuery,
    currentTab,
    startDate,
    endDate,
    selectedAssignmentGroups,
    centreLocations,
  ])

  useEffect(() => {
    const handleResize = () => {
      setHeight(currentUser.is_quickcare_admin?  window.innerHeight - ADMIN_HEIGHT: window.innerHeight - USER_HEIGHT);
    }

    window.addEventListener('resize', handleResize)
  }, [currentUser.is_quickcare_admin])

  const renderQuickCareAdminFilters = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <DatePickerTextField
          label={'Start date'}
          onChange={(event) => updateDateFilter(true, event.target.value)}
          value={startDate}
          fullWidth
          maxDate={getMaxDate()}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <DatePickerTextField
          label={'End date'}
          onChange={(event) => updateDateFilter(false, event.target.value)}
          value={endDate}
          fullWidth
          maxDate={getMaxDate()}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FilterButton
          currentValue={selectedOrganisation}
          onChange={handleSelectedOrganisationChange}
          placeholder={'Select Organisation'}
          options={[{ id: DEFAULT_ORG, name: 'All' }, ...organisationOptions]}
          fullWidth
          customMargin={'0'}
        ></FilterButton>
        <PaddingBoxSmall />
        <FilterButtonMultiple
          currentValue={selectedCentreLocation}
          onChange={handleSelectedCentresChange}
          options={centreLocations}
          placeholder="Centre location"
          isDisabled={disableCentre}
          fullWidth
          customMargin={'0'}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FilterButtonMultiple
            currentValue={selectedAssignmentGroups}
            onChange={handleAssignmentGroupChange}
            options={[
                { id: 'org', name: getOrgName },
                { id: 'quickcare', name: 'QuickCare' },
                { id: 'individual-assigned', name: 'Individuals' }
            ]}
            placeholder="Assigned to"
            label=""
            fullWidth
            customMargin={'0'}
        />
      </Grid>
      <Grid item xs={2} style={{ visibility: (currentTab === SHIFT_TABS.EXPIRED) ? 'hidden' : 'visible' }}>
        <Switch
          value={filterUnreadMessageStatus === unreadMessageFilter[1].id}
          onChange={(event, value) => {
            setFilterUnreadMessageStatus(
              value ? unreadMessageFilter[1].id : unreadMessageFilter[0].id
            )
          }}
        />
        <FormLabel>
          <M3TitleSmall>New messages</M3TitleSmall>
        </FormLabel>
      </Grid>
      <Grid item xs={12} sm={2}>
      <SpacedRow>
          <AddButton
            text="Create new shift"
            disabled={isDisabled}
            onClick={() => {
              setOpenModal(true)
            }}
          />
        </SpacedRow>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <CreateShiftSuccessModal
        open={openModalCreateShiftSuccess}
        onClose={() => {
          setOpenModalCreateShiftSuccess(false)
        }}
        data={{
          centre: centreCreatedShift,
          shifts: createdShifts
        }}
      />
      <CreateShiftModal
        open={openModal}
        centres={centreLocations}
        qualifications={qualifications}
        orgId={getOrgId}
        orgName={orgName}
        onClickCreate={handlePostShift}
        onCancel={() => {
          setOpenModal(false)
        }}
      />
      {!currentUser.is_quickcare_admin && (
        <SpacedRow>
          <M3TitleLarge>Shifts</M3TitleLarge>
          <AddButton
            text="Create new shift"
            onClick={() => {
              setOpenModal(true)
            }}
          />
        </SpacedRow>
      )}
      <Box sx={{ padding: '20px 0' }}>
        {currentUser.is_quickcare_admin ? (
          renderQuickCareAdminFilters()
        ) : (
          <Row>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <DatePickerTextField
                  label={'Start date'}
                  onChange={(event) => {
                    updateDateFilter(true, event.target.value)
                  }}
                  value={startDate}
                  maxDate={getMaxDate()}
                ></DatePickerTextField>
              </Grid>
              <Grid item xs={2}>
                <DatePickerTextField
                  label={'End date'}
                  onChange={(event) => {
                    updateDateFilter(false, event.target.value)
                  }}
                  value={endDate}
                  maxDate={getMaxDate()}
                ></DatePickerTextField>
              </Grid>
              <Grid item xs={2}>
                <FilterButtonMultiple
                  currentValue={selectedCentreLocation}
                  onChange={handleSelectedCentresChange}
                  options={centreLocations}
                  placeholder="Centre location"
                  customMargin={'0'}
                  fullWidth
                ></FilterButtonMultiple>
                <PaddingBoxSmall />
                <FilterButtonMultiple
                  currentValue={selectedAssignmentGroups}
                  onChange={handleAssignmentGroupChange}
                  options={[
                    { id: 'org', name: orgName },
                    { id: 'quickcare', name: 'QuickCare' },
                    { id: 'individual-assigned', name: 'Individuals' }
                  ]}
                  placeholder="Assigned to"
                  label=""
                  fullWidth
                  customMargin={'0'}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  visibility:
                    currentTab === SHIFT_TABS.EXPIRED
                      ? 'hidden'
                      : 'visible'
                }}
              >
                <Switch
                  value={
                    filterUnreadMessageStatus === unreadMessageFilter[1].id
                  }
                  onChange={(event, value) => {
                    setFilterUnreadMessageStatus(
                      value
                        ? unreadMessageFilter[1].id
                        : unreadMessageFilter[0].id
                    )
                  }}
                />
                <FormLabel>
                  <M3TitleSmall>New messages</M3TitleSmall>
                </FormLabel>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  visibility:
                    currentTab === SHIFT_TABS.OPEN ? 'hidden' : 'visible'
                }}
              >
                <SearchBox
                  value={searchingQuery}
                  onChange={(e) => {
                    onChangeSearch(e.target.value)
                  }}
                  placeholder="Search for an educator"
                  fullWidth
                ></SearchBox>
              </Grid>
            </Grid>
          </Row>
        )}
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.outline.main,
          marginBottom: '20px'
        }}
      >
        <CustomTabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="info"
          centered
        >
          {tabValues.map((tab, index) => (
            <CustomTab
              key={index}
              label={tab.name}
              icon={
                <CustomBadge
                  badgeContent={
                    totalShifts &&
                    totalShifts[tab.name.toLowerCase().replace(/ /g, '')]
                  }
                />
              }
              iconPosition="end"
            />
          ))}
        </CustomTabs>
      </Box>

      {getShifts() && getShifts().length === 0 && !isLoading ? (
        <Grid container justifyContent="center">
          {' '}
          <M3TitleLarge>No shifts found</M3TitleLarge>{' '}
        </Grid>
      ) : null}

      {isLoading && currentPage === 1 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          {' '}
          <CircularProgress />{' '}
        </Box>
      ) : (
        <div style={{ height: `${height}px`, overflow: 'hidden' }}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                className="List"
                height={height}
                onScroll={({ scrollOffset }) => {
                  if (
                    scrollOffset >= (useContext.is_quickcare_admin || currentTab === SHIFT_TABS.OPEN? 60: 20) * getShifts().length &&
                    getShifts().length > 0 &&
                    currentPage <= lastPage &&
                    !isLoading
                  ) {
                    setCurrentPage(currentPage + 1)
                    fetchShiftMessage(false, currentPage + 1)
                  }
                }}
                ref={listRef}
                width={width}
                itemSize={(currentUser.is_quickcare_admin || currentTab === SHIFT_TABS.OPEN) ? 200 : 120 }
                itemCount={shifts.length}
              >
                {({ index, style }) => {
                  const shift = getShifts()[index]
                  return (
                    shift &&
                    <div key={shift.id} style={style}>
                      {checkIfDateIn7Day(shift.cancel_date) &&
                      index === 0 &&
                      currentTab === SHIFT_TABS.COMPLETED ? (
                        <LabelDivider label={'Last week'} />
                      ) : null}
                      {checkIfDateIn7Day(shift.cancel_date) &&
                      index === 0 &&
                      currentTab === SHIFT_TABS.CANCELLED ? (
                        <LabelDivider label={'Last week'} />
                      ) : null}
                      <ShiftItemList
                        onClick={() => {
                          navigateToDetail(shift.id)
                        }}
                        params={{
                          shift,
                          isOpenTab: currentTab === SHIFT_TABS.OPEN
                        }}
                      ></ShiftItemList>
                      {index < getShifts().length - 1 &&
                      checkIfDateIn7Day(shift.cancel_date) &&
                      !checkIfDateIn7Day(getShifts()[index + 1].shift_date) &&
                      currentTab === SHIFT_TABS.COMPLETED ? (
                        <LabelDivider label={'Older'} />
                      ) : null}
                      {index < getShifts().length - 1 &&
                      checkIfDateIn7Day(shift.cancel_date) &&
                      !checkIfDateIn7Day(getShifts()[index + 1].updated_at) &&
                      currentTab === SHIFT_TABS.CANCELLED ? (
                        <LabelDivider label={'Older'} />
                      ) : null}
                      {index === getShifts().length - 1 &&
                        currentPage < lastPage && (
                          <CircularProgress
                            sx={{
                              display: 'block',
                              margin: 'auto'
                            }}
                          />
                        )}
                    </div>
                  )
                }}
              </List>
            )}
          </AutoSizer>
        </div>
      )}
    </div>
  )
};


export default Shifts;